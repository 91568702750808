@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');

.navbar {
  margin-bottom: 100px;
  border: 1px solid lightgrey;
  border-style: none none solid none;
  padding: 25px;
}

.nav-link {
  font-size: 25px;
  font-family: 'Raleway';
  color: black;
  text-decoration: none;
  margin: 0px 15px;
}

.nav-link:hover {
  font-weight: bold;
}

.nav-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  top: 15px;
  right: 20px;
}

.nav-logo {
  width: 100px;
  height: 100px;
  margin: 0px 20px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.nav-title {
  font-size: 60px;
  font-family: 'Raleway';
  font-weight: bold;
  text-decoration: none;
  color: rgb(65, 239, 115);
}

.nav-title:hover {
  opacity: 50%;
}

.nav-title-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1000px) {
  .nav-link {
    font-size: 20px;
  }

  .nav-title {
    font-size: 50px;
    margin-top: 20px;
  }
}

@media screen and (max-width: 750px) {
  .navbar {
    margin-bottom: 50px; 
  }
  .nav-link {
    font-size: 15px;
  }

  .nav-logo {
    display: none;
  }

  .nav-title {
    font-size: 30px;
  }
}