.home-contact-link {
  text-decoration: none;
  font-weight: bold;
  color: black;
}

.home-contact-link:hover {
  opacity: 50%;
}

.home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-list {
  margin: 70px 0px;
  font-family: 'Raleway';
  font-size: 20px;
  list-style: '➝';
}

.home-info {
  font-family: 'Raleway';
  font-size: 25px;
  text-align: center;
  width: 50%;
}

.list-item {
  padding-left: 10px;
  margin: 5px 0px;
}

.pic {
  width: 400px;
  height: 300px;
  border: 2px solid black;
  margin-bottom: 50px;
}

.pic-container {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 150px;
  width: 80%;
}

.pic-info {
  font-family: 'Raleway';
  font-size: 25px;
  text-align: center;
  width: 50%;
  margin-top: 100px;
}

.pic-title {
  margin: 50px 0px 25px 0px;
  font-size: 30px;
  font-family: 'Raleway';
  font-weight: bold;
}

@media screen and (max-width: 1250px) {

  .pic-container {
    grid-gap: 75px
  }

  .pic {
    width: 300px;
    height: 200px;
  }

}

@media screen and (max-width: 875px) {

  .pic-container {
    grid-gap: 50px;
  }

  .pic {
    width: 200px;
    height: 150px;
  }

}

@media screen and (max-width: 600px) {

  .pic-container {
    grid-gap: 20px;
  }

  .home-info {
    font-size: 12px;
    width: 70%;
  }

  .home-list {
    margin: 30px 0px;
    font-size: 12px;
  }

  .pic-info {
    font-size: 12px;
    margin-top: 50px;
  }

}

@media screen and (max-width: 450px) {

  .pic {
    width: 125px;
    height: 100px;
  }

}
